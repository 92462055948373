.transactions {
  h1 {
    visibility: hidden;
  }

  &-loans {
    margin: 165px 0 106px 0;

    @media (max-width: $smallScreen) {
      margin: 184px 0 80px 0;
    }

    &__wrapper {
      border-bottom: 1px solid $black;
      display: flex;
      justify-content: space-between;
      padding: 49px 0 76px 0;
      margin: 0 responsive(36, 54);

      @media (max-width: $smallScreen) {
        flex-direction: column;
        padding: 41px 0 42px 0;
      }

      .loanInfo {
        width: 40.2%;

        @media (max-width: $smallScreen) {
          width: 100%;
        }

        h3 {
          @include graphik-medium;
          font-size: 27px;
          letter-spacing: 3.38px;
          margin-bottom: 22px;
          text-transform: uppercase;

          @media (max-width: $smallScreen) {
            margin-bottom: 53px;
          }
        }

        .loanValue {
          color: $darkGrey;
          @include financier-display-light;
          font-size: 34px;
          margin-bottom: 51px;

          @media (max-width: $smallScreen) {
            margin-bottom: 43px;
          }
        }

        .loanText {
          @include financier-regular;
          font-size: 20px;
          margin-bottom: 43px;
        }
      }

      .loanImage {
        max-height: 362px;
        object-fit: cover;
        width: 45vw;
        
        @media (max-width: $smallScreen) {
          margin-top: 91px;
          width: 100%;
        }
      }
    }
  }
}