#contact-us {
  align-items: center;
  background-color: $greyBackground;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 98px #{map-get($small, 'outer')}px 104px;
  width: 100vw;

  @media (min-width: #{map-get($medium, 'width')}px) {
    padding: 61px #{map-get($medium, 'outer')}px 58px;
  }

  .contact-us-title {
    @include financier-display-semibold;
    font-size: 40px;
    letter-spacing: 0.4px;
    line-height: 52px;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: #{map-get($medium, 'width')}px) {
      line-height: 43px;
      width: medium-grid(6);
    }

    &:not(:last-child) {
      margin-bottom: 26px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        margin-bottom: 20px;
      }
    }
  }

  .contact-us-description {
    @include financier-display-light;
    font-size: 26px;
    line-height: 34px;
    text-align: center;

    @media (min-width: #{map-get($medium, 'width')}px) {
      width: medium-grid(7);
    }

    &:not(:last-child) {
      margin-bottom: 39px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        margin-bottom: 28px;
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: 34px;
      }
    }
  }

  .contact-us-show-form {
    @include graphik-medium;
    border-bottom: solid 1px $darkGrey;
    color: $darkGrey;
    display: block;
    font-size: 14px;
    letter-spacing: 2.1px;
    line-height: 14px;
    padding-bottom: 6px;
    text-transform: uppercase;
    width: fit-content;
  }
}

#contact-us-modal {
  align-items: center;
  background-color: $black;
  color: $white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: -120%;
  opacity: 0;
  overflow-y: auto;
  padding: #{map-get($small, 'outer')}px;
  position: fixed;
  top: 0;
  transition: opacity 300ms;
  width: 100%;

  @media (min-width: #{map-get($medium, 'width')}px) {
    border: solid 1px $white;
    height: initial;
    min-height: 73vh;
    padding: 44px;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: medium-grid(6);
  }

  &.show {
    left: 0;
    opacity: 1;

    @media (min-width: #{map-get($medium, 'width')}px) {
      left: 50%;
    }
  }

  .contact-us-hide-form {
    background-image: url('#{map-get($paths, "icons")}/close-white.svg');
    height: 10px;
    position: fixed;
    right: 20px;
    top: 20px;
    width: 10px;
  }

  .contact-us-modal-form-title {
    @include financier-display-semibold;
    font-size: 34px;
    line-height: 37px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: #{map-get($medium, 'width')}px) {
      margin: 0 auto;
      width: medium-grid(4);
    }
    
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .contact-us-modal-form-text {
    @include financier-display-light;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 62px;
    text-align: center;
    width: 100%;

    @media (min-width: #{map-get($medium, 'width')}px) {
      font-size: 26px;
      line-height: 34px;
      margin: 0 auto 69px;
      text-align: center;
      width: medium-grid(5);
    }

    p {
      &:not(:last-child) {
        margin-bottom: 26px;
      }
    }
  }

  [role=form] {
    > form {
      p {
        &:not(:last-child) {
          margin-bottom: 45px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            margin-bottom: 46px;
          }
        }

        label {
          span {
            @include graphik-regular;
            color: $lightGrey;
            width: 100%;

            @media (min-width: #{map-get($medium, 'width')}px) {
              font-size: 12px;
              letter-spacing: 0.18px;
              line-height: 12px;
              padding-bottom: 10px;
            }
          }

          input {
            @include graphik-regular;
            border-bottom: 1px solid $lightGrey;
            color: $lightGrey;
            padding-bottom: 12px;
            width: 100%;

            @media (min-width: #{map-get($medium, 'width')}px) {
              font-size: 12px;
              letter-spacing: 0.18px;
              line-height: 12px;
              padding-bottom: 10px;
            }
          }
        }

        input[type=submit] {
          @include graphik-medium;
          border-bottom: solid 1px $white;
          color: $white;
          cursor: pointer;
          display: block;
          font-size: 14px;
          letter-spacing: 2.1px;
          line-height: 14px;
          margin: 0 auto;
          padding-bottom: 6px;
          position: relative;
          text-transform: uppercase;
          width: fit-content;
        }
      }
    }
  }

  .contact-us-modal-form {


    .contact-us-modal-form-input {
      @include graphik-regular;
      border-bottom: 1px solid $lightGrey;
      color: $lightGrey;
      padding-bottom: 12px;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 45px;


      }
    }


  }
}
