.institutionalHero {
  align-items: center;
  background-color: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 61vh;
  width: 100vw;

  &-title, &-subtitle, &-link {
    color: $white;
    margin-bottom: 16px;
  }

  &-title {
    @include financier-display-semibold;
    font-size: 50px;
    text-transform: uppercase;

    @media (max-width: $smallScreen) {
      font-size: 38px;
    }
  }

  &-subtitle {
    @include financier-display-light;
    font-size: 26px;
    line-height: 1.5;
    max-width: 40%;
    text-align: center;

    @media (max-width: $smallScreen) {
      font-size: 18px;
      max-width: 80%;
    }
  }

  &-link {
    cursor: pointer;
    font-size: 14px;
    position: relative;
    text-transform: uppercase;
    
    &::after {
      content: '';
      border-bottom: 1px solid $white;
      height: 2px;
      left: 0;
      position: absolute;
      top: 20px;
      width: 100%;
    }
  }
}

.institutionalHero-border {
  border: 1px solid white;
  border-top: none;
  height: 53vh;
  position: absolute;
  top: 4vh;
  left: 2vw;
  width: 95vw;

  @media (max-width: $smallScreen) {
    top: 4vh;
  }
}

.institutionalHero-logo {
  background-color: transparent;
  left: 40vw;
  top: 2vh;
  position: absolute;
  width: 20vw;

  &::after, &::before {
    border-top: 1px solid white;
    content: '';
    height: 2px;
    position: absolute;
    top: 2vh;
    width: 37vw;
  }

  &::after {
    left: 20vw;
  }

  &::before {
    right: 20vw;
  }

  @media (max-width: $smallScreen) {
    top: 2vh;

    &::after {
      left: 27vw;
      width: 30vw;
    }

    &::before {
      right: 27vw;
      width: 24vw;
    }
  }

  &__text {
    align-items: flex-end;
    display: flex;
    color: white;
    height: 36px;
    font-size: responsive(16, 24);
    justify-content: center;
    text-transform: uppercase;
  }
}