#footer {
  align-items: center;
  background-color: $darkGrey;
  color: $white;
  @include financier-regular;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 1.8px;
  width: 100%;

  .inactive-footer {
    opacity: 0.7;
    transition: opacity 0.3s;
  }

  .footer-nav {
    border-bottom: 1px solid $mediumGrey;
    display: flex;
    justify-content: space-evenly;
    padding: 64px responsive(36, 54) 32px responsive(36, 54);
    width: 80%;

    @media (max-width: $smallScreen) {
      flex-direction: column-reverse;
    }

    &-list {
      font-size: 12px;
      text-transform: uppercase;

      @media (max-width: $smallScreen) {
        margin-bottom: 60px;
      }

      &__item {
        text-align: center;
        margin-bottom: 4px;
      }
    }
  }

  .footer-info {
    padding: 32px 0 64px 0;

    @media (max-width: $smallScreen) {
      padding: 32px 18px 64px 18px;
    }
  
    p {
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 4px;
      line-height: 20px;
    }

    .italic {
      font-style: italic;
    }
  }
}