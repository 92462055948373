#news-bridgeton {

  .news-bridgeton-list {
    margin-top: 100px;
  }

  .logo-carousel {
    background-color: $darkGrey;
    height: 200px;
    width: 100%;
  }

  .news-description {
    background-color: $lightBackground;
    padding: 100px #{map-get($small, 'outer')}px 118px;

    @media (min-width: #{map-get($medium, 'width')}px) {
      padding: 100px #{map-get($medium, 'outer')}px 115px;
    }

    .news-description-content {
      @include financier-display-lightitalic;
      color: $darkGrey;
      font-size: 18px;
      line-height: 29px;
      text-align: center;

      @media (min-width: #{map-get($medium, 'width')}px) {
        font-size: 26px;
        line-height: 35px;
        margin: 0 auto;
        width: medium-grid(8);
      }

      &:not(:last-child) {
        margin-bottom: 60px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          margin-bottom: 50px;
        }
      }

      p {
        &:not(:last-child) {
          margin-bottom: 29px;
        }
      }
    }

    .news-description-link {
      .news-description-link-element {
        @include graphik-medium;
        border-bottom: solid 1px $darkGrey;
        color: $darkGrey;
        display: block;
        font-size: 14px;
        letter-spacing: 2.1px;
        line-height: 14px;
        margin: 0 auto;
        padding-bottom: 6px;
        text-transform: uppercase;
        width: fit-content;
      }
    }
  }

  .news-banner {
    background-position: center;
    background-size: cover;
    height: 400px;
    width: 100%;
  }
}
