.bridgetonCapital {
  align-items: center;
  background-color: $lightBackground;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 54px 121px 54px;

  @media (max-width: $smallScreen) {
    font-size: 18px;
    line-height: 1.5;
    padding: 99px 24px 125px 24px;
    width: 100%;
  }
  
  &-text {
    color: $darkGrey;
    @include financier-display-lightitalic;
    font-size: 26px;
    font-style: italic;
    margin-bottom: 48px;
    text-align: center;
    max-width: 56%;

    @media (max-width: $smallScreen) {
      font-size: 18px;
      line-height: 1.5;
      max-width: 100%;
    }
  }
}