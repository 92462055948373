.factSheets {
  background-color: transparent;

  h1 {
    visibility: hidden;
  }

  &-facts {
    border-bottom: 1px solid $black;
    display: flex;
    justify-content: center;
    margin: 0 responsive(36, 54);

    @media (max-width: $smallScreen) {
      border-bottom: none;
      flex-direction: column;
    }

    &__wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      img {
        display: block;
        height: auto;
        margin: 36px 0 72px 0;
        max-width: 100%;

        @media (min-width: #{map-get($medium, 'width')}px) {
          margin: 36px 0;
        }
      }

      p {
        @include graphik-medium;
        font-size: 26px;
        letter-spacing: 3.38px;
        text-transform: uppercase;

        @media (max-width: $smallScreen) {
          margin: 50px 0 80px 0;
        }
      }
    }
  }

  &-download {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px 0 54px 0;

    
    @media (max-width: $smallScreen) {
      margin: 0 responsive(36, 54) 80px responsive(36, 54);
    }

    &__label {
      color: $darkGrey;
      @include graphik-medium;
      font-size: 14px;
      letter-spacing: 2.1px;
      position: relative;
      text-transform: uppercase;    
      
      &::after {
        content: '';
        border-bottom: 1px solid $darkGrey;
        height: 2px;
        left: 0;
        position: absolute;
        top: 25px;
        width: 100%;
      }

      @media (max-width: $smallScreen) {
        &::after {
          top: 36px;
          width: 85%;
        }
      }
    }

    .wpcf7-form {
      align-items: center;
      border-bottom: 1px solid $lightGrey;
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      padding: 8px 0;
      width: 100%;

      @media (max-width: $smallScreen) {
        justify-content: center;
        margin: 30px 0 0 107px;
        width: 48%;
      }
      
      p {
        input {
          &::placeholder {
            color: $lightGrey;
            @include graphik-regular;
            font-size: 12px;
            
            @media (max-width: $smallScreen) {
              padding-left: 80px;
            }
          }
        }

        input[type=submit] {
          color: $lightGrey;
          @include graphik-medium;
          font-size: 14px;
          letter-spacing: 2.1px;
          text-transform: uppercase;
        }
      }
    }

    .wpcf7-spinner {
      display: none;
    }

    .wpcf7-response-output {
      display: none;
    }
  }
}