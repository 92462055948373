@font-face {
  @include graphik-regular;
  src: url('#{map-get($paths, "fonts")}/Graphik-Regular.eot');
  src: url('#{map-get($paths, "fonts")}/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{map-get($paths, "fonts")}/Graphik-Regular.woff2') format('woff2'),
    url('#{map-get($paths, "fonts")}/Graphik-Regular.woff') format('woff'),
    url('#{map-get($paths, "fonts")}/Graphik-Regular.ttf') format('truetype'),
    url('#{map-get($paths, "fonts")}/Graphik-Regular.svg#Graphik-Regular') format('svg');
}

@font-face {
  @include graphik-medium;
  src: url('#{map-get($paths, "fonts")}/Graphik-Medium.eot');
  src: url('#{map-get($paths, "fonts")}/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{map-get($paths, "fonts")}/Graphik-Medium.woff2') format('woff2'),
    url('#{map-get($paths, "fonts")}/Graphik-Medium.woff') format('woff'),
    url('#{map-get($paths, "fonts")}/Graphik-Medium.ttf') format('truetype'),
    url('#{map-get($paths, "fonts")}/Graphik-Medium.svg#Graphik-Medium') format('svg');
}

@font-face {
  @include graphik-light;
  src: url('#{map-get($paths, "fonts")}/Graphik-Light.eot');
  src: url('#{map-get($paths, "fonts")}/Graphik-Light.eot?#iefix') format('embedded-opentype'),
    url('#{map-get($paths, "fonts")}/Graphik-Light.woff2') format('woff2'),
    url('#{map-get($paths, "fonts")}/Graphik-Light.woff') format('woff'),
    url('#{map-get($paths, "fonts")}/Graphik-Light.ttf') format('truetype'),
    url('#{map-get($paths, "fonts")}/Graphik-Light.svg#Graphik-Light') format('svg');
}

@font-face {
  @include financier-regular;
  src: url('#{map-get($paths, "fonts")}/FinancierText-Regular.eot');
  src: url('#{map-get($paths, "fonts")}/FinancierText-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{map-get($paths, "fonts")}/FinancierText-Regular.woff2') format('woff2'),
    url('#{map-get($paths, "fonts")}/FinancierText-Regular.woff') format('woff'),
    url('#{map-get($paths, "fonts")}/FinancierText-Regular.ttf') format('truetype'),
    url('#{map-get($paths, "fonts")}/FinancierText-Regular.svg#FinancierText-Regular') format('svg');
}

@font-face {
  @include financier-regularitalic;
  src: url('#{map-get($paths, "fonts")}/FinancierText-RegularItalic.eot');
  src: url('#{map-get($paths, "fonts")}/FinancierText-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('#{map-get($paths, "fonts")}/FinancierText-RegularItalic.svg#FinancierText-RegularItalic') format('svg'),
    url('#{map-get($paths, "fonts")}/FinancierText-RegularItalic.ttf') format('truetype'),
    url('#{map-get($paths, "fonts")}/FinancierText-RegularItalic.woff') format('woff'),
    url('#{map-get($paths, "fonts")}/FinancierText-RegularItalic.woff2') format('woff2');
}

@font-face {
  @include financier-regularitalic;
  src: url('FinancierText-RegularItalic.eot');
  src: url('#{map-get($paths, "fonts")}/FinancierText-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('#{map-get($paths, "fonts")}/FinancierText-RegularItalic.woff2') format('woff2'),
    url('#{map-get($paths, "fonts")}/FinancierText-RegularItalic.woff') format('woff'),
    url('#{map-get($paths, "fonts")}/FinancierText-RegularItalic.ttf') format('truetype'),
    url('#{map-get($paths, "fonts")}/FinancierText-RegularItalic.svg#FinancierText-RegularItalic') format('svg');
}

@font-face {
  @include financier-display-semibold;
  src: url('#{map-get($paths, "fonts")}/FinancierDisplay-Semibold.eot');
  src: url('#{map-get($paths, "fonts")}/FinancierDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-Semibold.woff2') format('woff2'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-Semibold.woff') format('woff'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-Semibold.ttf') format('truetype'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-Semibold.svg#FinancierDisplay-Semibold') format('svg');
}

@font-face {
  @include financier-display-light;
  src: url('#{map-get($paths, "fonts")}/FinancierDisplay-Light.eot');
  src: url('#{map-get($paths, "fonts")}/FinancierDisplay-Light.eot?#iefix') format('embedded-opentype'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-Light.woff2') format('woff2'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-Light.woff') format('woff'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-Light.ttf') format('truetype'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-Light.svg#FinancierDisplay-Light') format('svg');
}

@font-face {
  @include financier-display-lightitalic;
  src: url('#{map-get($paths, "fonts")}/FinancierDisplay-LightItalic.eot');
  src: url('#{map-get($paths, "fonts")}/FinancierDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-LightItalic.woff2') format('woff2'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-LightItalic.woff') format('woff'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-LightItalic.ttf') format('truetype'),
    url('#{map-get($paths, "fonts")}/FinancierDisplay-LightItalic.svg#FinancierDisplay-LightItalic') format('svg');
}
