#header {
  position: relative;

  &.header-light {
    &.show {
      .header-logo {
        svg {
          fill: $white;
          @media (min-width: #{map-get($medium, 'width')}px) {            
            fill: $black;
          }
        }
      }
    }

    .header-logo {
      svg {
        fill: $black;
      }
    }
  }

  .header-logo {
    width: 168px;
    position: absolute;
    // top: 22px;
    top: 37px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;

    @media (min-width: #{map-get($medium, 'width')}px) {
      // top: 25px;
      top: 45px;
    }

    &.bridgeton-capital {
      width: 138px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        width: 248px;
      }

      .logo-small {
        display: block;
        width: 100%;

        @media (min-width: #{map-get($medium, 'width')}px) {
          display: none;
        }
      }

      .logo-large {
        display: none;

        @media (min-width: #{map-get($medium, 'width')}px) {
          display: block;
          width: 90%;
          margin: 0 auto;
        }
      }
    }

    svg {
      display: block;
      width: 100%;
      height: auto;
      fill: $white;
    }
  }
}
