#careers-capital {
  .careers-introduction {
    @include financier-display-light;
    color: $darkGrey;
    font-size: 26px;
    line-height: 34px;
    margin: 100px auto 138px;
    text-align: center;
    width: small-grid(4);

    @media (min-width: #{map-get($medium, 'width')}px) {
      margin: 100px auto 125px;
      width: medium-grid(8, map-get($medium, 'gutter') * 2);
    }

    @media (min-width: #{map-get($medium, 'width')}px) {
      line-height: 35px;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 34px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          margin-bottom: 35px;
        }
      }
    }
  }

  #job-listing {
    margin-bottom: 175px;

    @media (min-width: #{map-get($medium, 'width')}px) {
      margin-bottom: 156px;
    }
  }

  .jobs-iframe-container {
    margin: 0 auto 104px;
    width: fit-content;

    @media (min-width: #{map-get($medium, 'width')}px) {
      margin: 0 auto 58px;
    }
  }
}
