.login {
  background-color: $black;
  max-height: 100vh;
  max-width: 100vw;
  position: relative;

  &-form {
    align-items: center;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    height: 100vh;
    position: absolute;
    top: 0;
    width: 100vw;

    &__title {
      @include financier-display-semibold;
      font-size: 34px;
      margin-bottom: 36px;
      text-transform: uppercase;
    }

    &__input {
      border-bottom: 1px solid rgba($lightGrey, 0.5);
      padding: 8px 0;
      margin-bottom: 8px;
      width: 25%;

      &:first-of-type {
        margin-bottom: 18px;
      }

      &::placeholder {
        color: $lightGrey;
        @include graphik-regular;
        font-size: 14px;
        letter-spacing: 0.21px;
      }

      @media (max-width: $smallScreen) {
        width: 80%;
      }
    }

    &__button {
      color: $white;
      font-size: Graphik-Medium;
      font-size: 14px;
      letter-spacing: 2.1px;
      margin-top: 64px;
      position: relative;
      text-transform: uppercase;

      &::after {
        content: '';
        border-bottom: 1px solid $white;
        height: 2px;
        left: 0;
        position: absolute;
        top: 20px;
        width: 100%;
      }
    }
  }
}