.lendingProgram {
  &-inquire {
    align-items: center;
    background-color: $greyBackground;
    display: flex;
    flex-direction: column;
    height: 320px;
    justify-content: center;
    margin-bottom: 123px;
    text-align: center;
    width: 100vw;

    @media (max-width: $smallScreen) {
      height: 388px;
    }
    
    &__text {
      @include financier-display-light;
      font-size: 40px;
      margin-bottom: 42px;
      width: 60%;
      line-height: 40px;

      @media (max-width: $smallScreen) {
        font-size: 34px;
        width: 80%;
      }

      p {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }

  &-details {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;

    .sectionTitle {
      align-self: flex-start;
      padding-bottom: 48px;
      letter-spacing: 0.34px;
      line-height: 37px;
      margin-left: #{map-get($small, 'outer')}px;
      margin-right: #{map-get($small, 'outer')}px;
      width: 93%;

      @media (min-width: #{map-get($medium, 'width')}px) {
        margin-left: #{map-get($medium, 'outer')}px;
        margin-right: #{map-get($medium, 'outer')}px;
      }
    }

    > div {
      border-bottom: 1px solid black;
      margin: 47px #{map-get($small, 'outer')}px 40px;
      padding-bottom: 70px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        margin: 47px auto 40px;
        width: medium-grid(12);
      }
    }
    
    &__infoWrapper {
      display: flex;
      margin: 48px 0;
      justify-content: space-between;

      @media (max-width: $smallScreen) {
        flex-direction: column;
        margin-bottom: 0;

        > div {
          &:not(:last-child) {
            margin-bottom: 80px;
          }
        }
      }

      &:first-child {
        > div {
          @media (min-width: #{map-get($medium, 'width')}px) {
            @include same-min-max-width(medium-grid(2));
          }
        }
      }

      &:nth-child(2) {
        > div {
          @media (min-width: #{map-get($medium, 'width')}px) {
            @include same-min-max-width(medium-grid(4));
          }

          ul {
            list-style: none;
            margin-left: 0;
            padding-left: 0.65em;
            text-indent: -0.65em;
            width: 100%;

            li {
              &:before {
                content: '– ';
              }
            }
          }
        }
      }

      .detailTitle {
        @include graphik-medium;
        color: $subtitle;
        font-size: 14px;
        letter-spacing: 2.1px;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      
      .detailText {
        @include financier-regular;
        font-size: 20px;
        width: 279px;
      }
    }
    
    &__link {
      @include graphik-medium;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 2.1px;
      margin: 0 #{map-get($small, 'outer')}px;
      position: relative;
      text-transform: uppercase;

      @media (min-width: #{map-get($medium, 'width')}px) {
        margin: 0 #{map-get($medium, 'outer')}px;
      }

      &::after {
        content: '';
        border-bottom: 1px solid black;
        height: 2px;
        left: 0;
        position: absolute;
        top: 25px;
        width: 100%;

        @media (max-width: $smallScreen) {
          top: 40px;
        }
      }
    }
  }
}