.hero-institutional {
  background-color: $black;
  background-position: center;
  background-size: cover;
  display: flex;
  min-height: 490px;
  padding: 22px 16px;
  position: relative;
  width: 100%;

  @media (min-width: #{map-get($medium, 'width')}px) {
    padding: 25px;
  }

  &.hero-light {
    background-color: $white;
    min-height: 225px;
    position: relative;

    .hero-content {
      .border-top {
        display: none;

        @media (min-width: #{map-get($medium, 'width')}px) {
          display: block;
        }

        &:before,
        &:after {
          background-color: $black;
        }
      }

      .border-side,
      .border-bottom {
        display: none;
      }
    }

    #show-navigation {
      &:not(.show) {        
        svg {
          fill: $black;
        }
      } 
    }
  }

  &.hero-capital {
    .hero-content {
      .border-top {
        &:before,
        &:after {
          @media (min-width: #{map-get($medium, 'width')}px) {
            width: calc(50% - 44px - (248px / 2));
          }
        }
      }
    }
  }

  .hero-content {
    height: inherit;
    padding-top: 132px;
    position: relative;
    width: 100%;

    .border-top,
    .border-side,
    .border-bottom {
      &:before,
      &:after {
        background-color: $white;
        content: '';
        display: block;
        position: absolute;
      }
    }

    .border-top {
      &:before {
        left: 25px;
        // width: calc(50% - 15px - 10px - 14px - (168px / 2));
        width: calc(100% - 15px - 10px);


        @media (min-width: #{map-get($medium, 'width')}px) {
          left: 0;
          width: 100%;
        }
      }

      &:after {
        right: 0;
        width: calc(50% - 14px - (168px / 2));
        display: none;
      }

      &:before,
      &:after {
        height: 2px;
        top: 0;

        // @media (min-width: #{map-get($medium, 'width')}px) {
        //   width: calc(50% - 44px - (168px / 2));
        // }
      }
    }

    .border-side {
      &:before,
      &:after {
        width: 2px;
      }

      &:before {
        height: calc(100% - 25px);
        left: 0;
        top: 25px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          height: 100%;
          top: 0;
        }
      }

      &:after {
        height: 100%;
        right: 0;
        top: 0;
      }
    }

    .border-bottom {
      &:before {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
      }
    }

    .hero-content-title,
    .hero-content-subtitle,
    .hero-content-link-container {
      color: $white;
      margin: 0 auto;
      text-align: center;
      width: small-grid(4);

      @media (min-width: #{map-get($medium, 'width')}px) {
        width: medium-grid(6);
      }
    }

    .hero-content-title {
      @include financier-display-semibold;
      font-size: 38px;
      line-height: 41px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        font-size: 50px;
        line-height: 54px;
      }

      &:not(:last-child) {
        margin-bottom: 36px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          margin-bottom: 20px;
        }
      }
    }

    .hero-content-subtitle {
      @include financier-display-light;
      font-size: 26px;
      line-height: 34px;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    .hero-content-link-container {
      .hero-content-link {
        @include graphik-medium;
        border-bottom: solid 1px $white;
        color: inherit;
        display: inline-block;
        font-size: 14px;
        letter-spacing: 2.1px;
        line-height: 22px;
        text-transform: uppercase;
        width: fit-content;
      }
    }
  }

  #show-navigation {
    height: 15px;
    left: 16px;
    position: absolute;
    top: 22px;
    transition: transform 100ms;
    width: 15px;
    z-index: 999;

    @media (min-width: #{map-get($medium, 'width')}px) {
      display: none;
    }

    &.show {
      svg {
        polygon {
          &:nth-child(1) {
            transform: translateY(0) rotate(45deg);
            transform-origin: center;
            transition: transform 300ms;
            transform: translateY(0) rotate(45deg);
            -ms-transform: translateY(0) rotate(45deg);
            -webkit-transform: translateY(0) rotate(45deg);
            transform-origin: center;
            -ms-transform-origin: center;
            -webkit-transform-origin: center;
            transition: -webkit-transform 300ms;
            -webkit-transition: -webkit-transform 300ms;
            transition: transform 300ms, -webkit-transform 300ms;
            transition: transform 300ms;
            -o-transition: transform 300ms;
          }

          &:nth-child(2),
          &:nth-child(3) {
            transform: translateY(0) rotate(-45deg);
            -ms-transform: translateY(0) rotate(-45deg);
            -webkit-transform: translateY(0) rotate(-45deg);
            transform-origin: center;
            -ms-transform-origin: center;
            -webkit-transform-origin: center;
            transition: -webkit-transform 300ms;
            -webkit-transition: -webkit-transform 300ms;
            transition: transform 300ms, -webkit-transform 300ms;
            transition: transform 300ms;
            -o-transition: transform 300ms;
          }
        }
      }
    }

    svg {
      display: block;
      fill: $white;
      height: auto;
      width: 100%;

      polygon {
        &:nth-child(1) {
          transform: translateY(33.33%) rotate(0);
          -ms-transform: translateY(33.33%) rotate(0);
          -webkit-transform: translateY(33.33%) rotate(0);
        }

        &:nth-child(2) {
          transform: translateY(0) rotate(0);
          -ms-transform: translateY(0) rotate(0);
          -webkit-transform: translateY(0) rotate(0);
        }

        
        &:nth-child(3) {
          transform: translateY(-33.33%) rotate(0);
          -ms-transform: translateY(-33.33%) rotate(0);
          -webkit-transform: translateY(-33.33%) rotate(0);
        }
      }
    }
  }
}
