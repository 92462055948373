#development-bridgeton {
  position: relative;

  .development-image {
    height: 600px;
    background-position: center;
    background-size: cover;

    &:not(:last-child) {
      margin-bottom: 105px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        margin-bottom: 102px;
      }
    }
  }

  .development-description {
    margin: 0 auto 120px;
    width: small-grid(4);

    @media (min-width: #{map-get($medium, 'width')}px) {
      margin: 0 auto 100px;
      width: medium-grid(8);
    }

    .development-description-content {
      @include financier-display-light;
      font-size: 26px;
      line-height: 34px;
      color: $darkGrey;
      text-align: center;

      p {
        &:not(:last-child) {
          margin-bottom: 34px;
        }
      }
    }

    .development-description-link {
      @include graphik-medium;
      width: fit-content;
      color: $darkGrey;
      margin: 39px auto 0;
      padding-bottom: 6px;
      border-bottom: solid 1px $darkGrey;
      display: block;
    }
  }
}