#hospitality-bridgeton {
  color: $black;
  position: relative;

  .hospitality-description {
    margin: 100px auto;
    width: small-grid(4);

    @media (min-width: #{map-get($medium, 'width')}px) {
      width: medium-grid(10);
    }

    .hospitality-description-content {
      @include financier-display-light;
      color: $darkGrey;
      font-size: 26px;
      line-height: 34px;
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    .hospitality-description-link {
      @include graphik-medium;
      border-bottom: solid 1px $darkGrey;
      color: $darkGrey;
      display: block;
      font-size: 14px;
      letter-spacing: 2.1px;
      line-height: 14px;
      margin: 0 auto;
      padding-bottom: 6px;
      text-transform: uppercase;
      width: fit-content;
    }
  }

  .hospitality-link-list {
    padding: 94px 24px;
    background-position: center;
    background-size: cover;

    @media (min-width: #{map-get($medium, 'width')}px) {
      padding: 111px #{map-get($medium, 'outer')}px 91px;
    }

    .link-item-wrapper {
      border-bottom: solid 3px transparent;
      padding-bottom: 7px;
      transition: border-bottom 300ms;
      width: fit-content;

      @media (min-width: #{map-get($medium, 'width')}px) {
        border-bottom: solid 6px transparent;
        padding-bottom: 14px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &:hover {
        border-bottom: solid 3px $white;

        @media (min-width: #{map-get($medium, 'width')}px) {
          border-bottom: solid 6px $white;
        }
      }

      .link-item-element {
        @include graphik-medium;
        color: $white;
        font-size: 38px;
        letter-spacing: 5.7px;
        line-height: 38px;
        text-transform: uppercase;
        width: fit-content;

        @media (min-width: #{map-get($medium, 'width')}px) {
          font-size: 60px;
          letter-spacing: 9px;
          line-height: 60px;
        }
      }
    }
  }
}
