.story {
  color: $black;
  position: relative;

  h1 {
    visibility: hidden;
  }

  &-portfolio {
    align-items: center;
    background-color: $greyBackground;
    display: flex;
    flex-direction: column;
    // height: 596px;
    justify-content: center;
    margin-bottom: 81px;
    width: 100vw;
    padding: 100px 0;

    @media (max-width: $smallScreen) {
      // height: 1012px;
      margin-bottom: 100px;
      padding: 120px 0;
    }

    &__text {
      @include financier-display-light;
      text-align: center;
      font-size: 38px;
      // margin-bottom: 40px;
      width: 70%;

      @media (max-width: $smallScreen) {
        width: 90%;
      }

      p {
        &:not(:last-child) {
          margin-bottom: 34px;
        }
      }
    }
  }

  &-about {
    margin-bottom: 106px;

    @media (max-width: $smallScreen) {
      margin-bottom: 196px;
    }

    &__text {
      @include financier-regular;
      display: flex;
      font-size: 20px;
      justify-content: space-between;
      margin: 40px responsive(36, 54);

      @media (max-width: $smallScreen) {
        flex-direction: column;
      }

      .content {
        width: 48%;

        @media (max-width: $smallScreen) {
          width: 100%;
        }
      }
    }

    &__link {
      margin: 0 responsive(36, 54);
    }
  }

  &-team {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 45px responsive(26, 44) 0 responsive(26, 44);
      justify-content: space-between;
      text-transform: uppercase;

      @media (max-width: $smallScreen) {
        margin: 45px 0 0 0;
      }

      > div {
        width: calc(25% - 20px);
        line-height: 0;
        margin: 0 10px;

        @media (max-width: $smallScreen) {
          width: calc(50% - 20px);
        }
        
        img {
          height: 362px;
          object-fit: cover;
          margin-bottom: 32px;
          width: calc(100% - 2px);
          border: solid 1px $black;

          &:last-child {
            margin-bottom: 0;
          }

          @media (max-width: $smallScreen) {
            height: 243px;
            margin-bottom: 0;
          }
        }

        .teamName {
          @include graphik-medium;
          font-size: 22px;
          line-height: 28px;
          letter-spacing: 2.25px;
          margin-bottom: 19px;

          @media (max-width: $smallScreen) {
            display: none;
          }
        }

        .teamPosition {
          @include graphik-medium;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 1.1px;
          margin-bottom: 68px;

          @media (max-width: $smallScreen) {
            display: none;
          }
        }
      }
    }
  }

  &-contact {
    margin-top: 142px;
    margin-bottom: 80px;

    &__info {
      @include financier-regular;
      border-bottom: 1px solid $black;
      display: flex;
      font-size: 18px;
      justify-content: space-between;
      padding-bottom: 50px;
      margin: 40px responsive(36, 54) 0 responsive(36, 54);
      
      @media (max-width: $smallScreen) {
        border-bottom: none;
        flex-direction: column;
        margin: 40px 0 0 0;
      }
      
      .content {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
        
        @media (max-width: $smallScreen) {
          margin: 0 responsive(36, 54) 0 responsive(36, 54);
        }
      }
      
      img {
        height: 511px;
        object-fit: cover;
        width: 70%;

        @media (max-width: $smallScreen) {
          margin-top: 115px;
          width: 100%;
        }
      }
    }
  }
}