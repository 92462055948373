#teams-bridgeton {
  .teams-lists {
    margin-top: 91px;

    &:not(:last-child) {
      margin-bottom: 100px; 
    }

    .teams-list-accordeon-image,
    .teams-list-accordeon-text {
      .accordeon-image-show,
      .accordeon-text-show {
        @include financier-display-semibold;
        color: $darkGrey;
        display: block;
        font-size: 34px;
        letter-spacing: 0.34px;
        line-height: 37px;
        margin: 0 auto;
        padding: 40px 20px 40px 0;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        width: small-grid(4);

        @media (min-width: #{map-get($medium, 'width')}px) {
          padding: 24px 0;
          width: medium-grid(12);
        }

        &.show {
          .icon {
            transform: translateY(-50%) rotate(225deg);
          }
        }

        .icon {
          background-image: url('#{map-get($paths, "icons")}/open.svg');
          display: block;
          height: 15px;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotate(0);
          transition: transform 300ms;
          width: 15px;
        }
      }
    }

    .teams-list-accordeon-image {
      position: relative;

      &:after {
        background-color: $darkGrey;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: small-grid(4);

        @media (min-width: #{map-get($medium, 'width')}px) {
          width: medium-grid(12);
        }
      }

      .teams-list-accordeon-image-gallery {
        display: flex;
        flex-wrap: wrap;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        transition: padding 300ms, max-height 300ms, opacity 300ms;

        @media (min-width: #{map-get($medium, 'width')}px) {
          padding: 0 #{map-get($medium, 'outer')}px;
        }

        &.show {
          max-height: 999em;
          opacity: 1;
          padding: 84px 0 40px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            padding: 44px #{map-get($medium, 'outer')}px 76px;
          }
        }

        .image-gallery-item-image {
          @include same-min-max-width(50vw);

          @media (min-width: #{map-get($medium, 'width')}px) {
            @include same-min-max-width(25%);
          }

          .item-image-element {
            background-position: center;
            background-size: cover;
            display: block;
            height: 61.5vw;
            opacity: 1;
            transition: opacity 300ms;
            width: 100%;

            @media (min-width: #{map-get($medium, 'width')}px) {
              height: 28.2vw;
            }

            &.disabled {
              opacity: 0.25;
            }

            &:hover {
              .item-image-element-name {
                opacity: 1;
              }
            }

            .item-image-element-name {
              display: none;

              @media (min-width: #{map-get($medium, 'width')}px) {
                @include graphik-medium;
                align-items: center;
                background-color: rgba($black, 0.35);
                color: $white;
                display: block;
                display: flex;
                font-size: 26px;
                height: 100%;
                justify-content: center;
                letter-spacing: 3.9px;
                line-height: 38px;
                pointer-events: none;
                text-transform: uppercase;
                transition: opacity 300ms;
                width: 100%;
              }
            }
          }

          .item-title, .item-subtitle {
            @include graphik-medium;
            color: $black;
            font-weight: 500;
            margin-top: 16px;
            text-transform: uppercase;
          }

          .item-title {
            font-size: 20px;
            letter-spacing: 3.2px;
          }
  
          .item-subtitle {
            font-size: 14px;
            letter-spacing: 1.8px;
            margin-bottom: 32px;
          }
        }

        .image-gallery-item-information {
          max-height: 0;
          opacity: 0;
          order: 9999;
          overflow: hidden;
          padding: 0 #{map-get($small, 'outer')}px;
          position: relative;
          transition-timing-function: linear;
          transition: max-height 300ms, padding 300ms, opacity 300ms;
          width: 100%;

          @media (min-width: #{map-get($medium, 'width')}px) {
            padding: 0;
          }

          &.active {
            max-height: 99em;
            opacity: 1;
            padding: 76px #{map-get($small, 'outer')}px;

            @media (min-width: #{map-get($medium, 'width')}px) {
              padding: 80px 0;
            }

            .item-information-hide {
              transform: rotate(225deg);
            }
          }

          .item-information-hide {
            background-image: url('#{map-get($paths, "icons")}/open.svg');
            display: block;
            height: 15px;
            position: absolute;
            right: 18px;
            top: 76px;
            transform: rotate(0);
            transition: transform 300ms;
            width: 15px;

            @media (min-width: #{map-get($medium, 'width')}px) {
              right: 0;
            }
          }

          .image-gallery-item-information-name {
            @include graphik-medium;
            color: $black;
            font-size: 27px;
            letter-spacing: 3.38px;
            line-height: 27px;
            margin-bottom: 15px;
            text-transform: uppercase;
            width: 100%;

            @media (min-width: #{map-get($medium, 'width')}px) {
              width: medium-grid(6);
            }
          }

          .image-gallery-item-information-ocupation {
            @include graphik-medium;
            color: $darkGrey;
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 36px;
            text-transform: uppercase;
            width: 100%;

            @media (min-width: #{map-get($medium, 'width')}px) {
              width: medium-grid(6);
            }
          }

          .image-gallery-item-information-description {
            @include financier-regular;
            color: $black;
            font-size: 20px;
            line-height: 30px;

            @media (min-width: #{map-get($medium, 'width')}px) {
              width: medium-grid(6);
            }

            p {
              &:not(:last-child) {
                margin-bottom: 30px;
              }
            }
          }
        }
      }
    }

    .teams-list-accordeon-text {
      position: relative;

      &:after {
        background-color: $darkGrey;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: small-grid(4);

        @media (min-width: #{map-get($medium, 'width')}px) {
          width: medium-grid(12);
        }
      }
      
      .teams-list-accordeon-text-gallery {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0 #{map-get($small, 'outer')}px;
        transition: padding 300ms, max-height 300ms, opacity 300ms;

        @media (min-width: #{map-get($medium, 'width')}px) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0 #{map-get($medium, 'outer')}px;
        }

        &.show {
          max-height: 999em;
          opacity: 1;
          padding: 40px #{map-get($small, 'outer')}px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            padding: 44px #{map-get($medium, 'outer')}px 0;
          }
        }

        .text-gallery-item {
          margin-bottom: 80px;
          opacity: 1;
          position: relative;
          transition: opacity 300ms;

          @media (min-width: #{map-get($medium, 'width')}px) {
            @include same-min-max-width(medium-grid(4));
          }

          &.disabled {
            opacity: 0.5;
          }

          .item-text-name {
            @include graphik-medium;
            color: $black;
            display: block;
            font-size: 27px;
            letter-spacing: 3.38px;
            line-height: 27px;
            margin-bottom: 15px;
            text-align: left;
            text-transform: uppercase;
            width: 100%;
          }

          .item-text-name-ocupation {
            @include graphik-medium;
            color: $darkGrey;
            font-size: 14px;
            letter-spacing: 2.1;
            line-height: 14px;
            text-transform: uppercase;
          }

          .item-text-information {
            @include financier-regular;
            color: $black;
            font-size: 20px;
            line-height: 30px;
            margin-top: 0;
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            transition: max-height 300ms, opacity 300ms, margin-top 300ms;

            &.show {
              margin-top: 44px;
              max-height: 999em;
              opacity: 1;
            }

            p {
              &:not(:last-child) {
                margin-bottom: 30px;
              }
            }
          }

          .item-text-information-hide {
            background-image: url('#{map-get($paths, "icons")}/open.svg');
            display: block;
            display: block;
            height: 15px;
            position: absolute;
            right: 0;
            top: 8px;
            transform: rotate(0);
            transition: transform 300ms;
            width: 15px;

            &.show {
              transform: rotate(225deg);
            }
          }
        }
      }
    }
  }
}
