@mixin reset-margin-padding {
  margin: 0;
  padding: 0;
}

@mixin font-inherit {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@mixin graphik-regular {
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
}

@mixin graphik-medium {
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
}

@mixin graphik-light {
  font-family: Graphik;
  font-style: normal;
  font-weight: 300;
}

@mixin financier-regular {
  font-family: Financier;
  font-style: normal;
  font-weight: normal;
}

@mixin financier-regularitalic {
  font-family: Financier;
  font-style: italic;
  font-weight: normal;
}

@mixin financier-display-semibold {
  font-family: FinancierDisplay;
  font-style: normal;
  font-weight: 600;
}

@mixin financier-display-light {
  font-family: FinancierDisplay;
  font-style: normal;
  font-weight: 300;
}

@mixin financier-display-lightitalic {
  font-family: FinancierDisplay;
  font-style: italic;
  font-weight: 300;
}

@mixin same-min-max-width($s) {
  max-width: $s;
  min-width: $s;
}
