#news-list {
  .news-list-head {
    @media (min-width: #{map-get($medium, 'width')}px) {
      border-bottom: solid 1px $black;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0 auto;
      padding-bottom: 20px;
      width: medium-grid(12);
    }

    .news-list-title {
      @include financier-display-semibold;
      color: $black;
      font-size: 34px;
      letter-spacing: 0.34px;
      line-height: 36px;
      margin: 0 auto 26px;
      text-transform: uppercase;
      width: small-grid(4);

      @media (min-width: #{map-get($medium, 'width')}px) {
        @include same-min-max-width(medium-grid(5));
        margin: 0;
      }
    }

    .news-search-form {
      border-bottom: solid 1px $black;
      margin: 0 auto;
      padding-bottom: 60px;
      position: relative;
      width: small-grid(4);

      @media (min-width: #{map-get($medium, 'width')}px) {
        @include same-min-max-width(medium-grid(3));
        border-bottom: none;
        margin: 0;
        padding-bottom: 0;
      }
  
      .news-search-form-input {
        @include graphik-regular;
        border-bottom: 1px solid $lightGrey;
        display: block;
        font-size: 12px;
        letter-spacing: 0.18px;
        line-height: 12px;
        padding-bottom: 10px;
        padding-right: 22px;
        width: 100%;
      }
  
      .news-search-form-submit {
        display: block;
        height: 12px;
        position: absolute;
        right: 0;
        top: 0;
        width: 12px;
      }
    }
  }

  .news-list-gallery {
    margin-bottom: 40px;
    // padding: 0 #{map-get($small, 'outer')}px;

    // @media (min-width: #{map-get($medium, 'width')}px) {
    //   padding: 0 #{map-get($medium, 'outer')}px;
    // }

    
    .news-list-item {
      border-bottom: solid 1px $black;
      margin: 0 auto;
      padding: 41px 0;
      width: small-grid(4);

      @media (min-width: #{map-get($medium, 'width')}px) {
        width: medium-grid(12);
      }

      &.full-width {
        border-bottom: none;
        position: relative;
        width: 100%;

        &:after {
          background-color: $black;
          bottom: 0;
          content: '';
          display: block;
          height: 1px;
          left: #{map-get($small, 'outer')}px;
          position: absolute;
          width: small-grid(4);

          @media (min-width: #{map-get($medium, 'width')}px) {
            left: #{map-get($medium, 'outer')}px;
            width: medium-grid(12);
          }
        }

        .news-list-item-show {
          padding: 0 #{map-get($small, 'outer')}px;
          width: 100%;

          @media (min-width: #{map-get($medium, 'width')}px) {
            padding: 0 medium-grid(4, map-get($medium, 'gutter') + map-get($medium, 'outer')) 0 #{map-get($medium, 'outer')}px;    
          }

          .icon {
            right: #{map-get($small, 'outer')}px;

            @media (min-width: #{map-get($medium, 'width')}px) {
              right: #{map-get($medium, 'outer')}px;
            }
          }
        }

        .news-list-item-content {
          .item-content-source-date {
            padding: 0 #{map-get($small, 'outer')}px;
  
            @media (min-width: #{map-get($medium, 'width')}px) {
              padding: 0 0 0 #{map-get($medium, 'outer')}px
            }
          }

          .item-content-link {
            padding: 0 #{map-get($small, 'outer')}px 6px;
  
            @media (min-width: #{map-get($medium, 'width')}px) {
              padding: 0 #{map-get($medium, 'outer')}px 6px;
            }
          }
        }
      }

      &.show {
        .news-list-item-show {
          .icon {
            transform: rotate(225deg);
          }
        }

        .news-list-item-content {
          max-height: 999em;
          opacity: 1;
          padding-top: 34px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            padding-top: 15px;
          }
        }
      }

      &.hide {
        display: none;
      }

      &.unfiltered {
        background: lime;
      }

      .news-list-item-show {
        @include graphik-medium;
        color: $black;
        display: block;
        font-size: 27px;
        letter-spacing: 3.38px;
        line-height: 36px;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        width: 100%;

        @media (min-width: #{map-get($medium, 'width')}px) {
          padding-right: medium-grid(4, map-get($medium, 'gutter'));
          width: medium-grid(12);
        }

        .icon {
          background-image: url('#{map-get($paths, "icons")}/open.svg');;
          display: block;
          height: 15px;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 4px;
          transform: rotate(0);
          transition: transform 300ms;
          width: 15px;
        }
      }

      .news-list-item-content {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding-top: 0;
        transition: opacity 300ms, max-height 300ms, padding-top 300ms;

        .item-content-source-date {
          @include graphik-medium;
          color: $black;
          font-size: 17px;
          letter-spacing: 2.55px;
          line-height: 17px;
          text-transform: uppercase;

          @media (min-width: #{map-get($medium, 'width')}px) {
            width: medium-grid(5);
          }

          &:not(:last-child) {
            margin-bottom: 41px;

            @media (min-width: #{map-get($medium, 'width')}px) {
              margin-bottom: 25px;
            }
          }
        }

        .item-content-text {
          @include financier-regular;
          font-size: 20px;
          line-height: 30px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            width: medium-grid(8);
          }

          &:not(:last-child) {
            margin-bottom: 41px;

            @media (min-width: #{map-get($medium, 'width')}px) {
              margin-bottom: 44px;
            }
          }
          
          p {
            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }
        }

        .item-content-image {
          display: block;
          height: auto;
          margin-top: 60px;
          width: 100%;

          @media (min-width: #{map-get($medium, 'width')}px) {
            margin-top: 41px;
          }
        }

        .item-content-gallery {
          height: 281px;
          position: relative;
          width: 100%;

          .slick-slide {
            margin: 0 10px;
          }
          
          .slick-list {
            margin: 0 -10px;
          }

          &:not(:last-child) {
            margin-bottom: 60px;

            @media (min-width: #{map-get($medium, 'width')}px) {
              margin-bottom: 41px;
            }
          }

          .item-content-gallery-item {
            background-position: center;
            background-size: cover;
            height: 281px;
            //left: 0;
            //osition: absolute;
            //top: 0;
          }
        }

        .item-content-link {
          padding-bottom: 6px;

          .item-content-link-element {
            @include graphik-medium;
            border-bottom: solid 1px $darkGrey;
            color: $darkGrey;
            font-size: 14px;
            letter-spacing: 2.1px;
            line-height: 14px;
            padding-bottom: 6px;
            text-transform: uppercase;
            width: fit-content;

            &:hover {
              border-bottom: solid 1px $lightGrey;
              color: $lightGrey;
              transition: color 0.5s, border-bottom 0.5s;
            }
          }
        }
      }
    }
  }

  .news-list-pagination {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto 90px;
    width: small-grid(4);

    @media (min-width: #{map-get($medium, 'width')}px) {
      width: medium-grid(12);
    }

    .news-list-pagination-item {
      width: fit-content;

      &:not(:first-child) {
        margin-left: 18px;
      }

      .pagination-item-button {
        @include graphik-medium;
        border-bottom: solid 2px transparent;
        color: rgba($black, 0.5);
        display: block;
        font-size: 17px;
        line-height: 17px;
        padding-bottom: 2px;
        width: fit-content;

        &.active {
          border-bottom: solid 2px $black;
          color: $black;
        }

        &.hide {
          display: none;
        }
      }
    }
  }
}
