@function responsive($value1, $value2, $width1: 320, $width2: 1920) {
  $x1: $width1;
  $x2: $width2;
  $y1: $value1;
  $y2: $value2;
  $a: ($y2 - $y1) / ($x2 - $x1);
  $b: ($x2 * $y1 - $x1 * $y2) / ($x2 - $x1);
  @return calc(#{$a * 100vw} + #{$b * 1px});
}

@function grid($c: 1, $mC: 1, $g: 0, $o: 0, $f: 0) {
  @return calc(((((100vw - (#{2 * $o}px) - (#{$mC - 1} * #{$g}px)) / #{$mC}) * #{$c}) + #{$g * ($c - 1)}px) + (#{$f}px));
}