.careers {
  &-description {
    @include financier-display-light;;
    color: $darkGrey;
    font-size: 26px;
    margin: 100px auto 125px auto;
    text-align: center;
    max-width: 809px;
  
    @media (max-width: $smallScreen) {
      max-width: 90%;
    }
  }

  &-jobList {
    margin-bottom: 165px;
  }
}