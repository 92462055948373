.accordeon {
  border-bottom: 1px solid black;
  margin: 0 responsive(36, 54) 0 responsive(36, 54);
  position: relative;

  &-title {
    @include graphik-medium;
    font-size: 26px;
    display: flex;
    justify-content: space-between;
    letter-spacing: 3.9px;
    line-height: 36px;
    padding: 32px 0;
    text-transform: uppercase;
  }

  &-open {
    background-image: url('#{map-get($paths, "icons")}/open.svg');
    height: 15px;
    width: 15px;
  }

  &-content {
    padding: 16px 0 64px 0;

    &.teams-content {
      display: flex;
      flex-wrap: wrap;
    }

    &__text {
      @include financier-regular;
      font-size: 20px;
      margin-bottom: 20px;
    }

    &__images {
      height: responsive(240, 360);
      object-fit: cover;
      position: relative;
      width: 25%;

      &:hover {
        p {
          align-items: center;
          background-color: rgba(0,0,0,0.4);
          display: flex;
          color: $white;
          font-size: 26px;
          height: 100%;
          left: 0;
          line-height: 1.5;
          justify-content: center;
          position: absolute;
          text-transform: uppercase;
          top: 0;
          width: 100%;
        }
      }

      @media (max-width: $smallScreen) {
        width: 50%;
      }

      p {
        display: none;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}