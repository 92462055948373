$paths: (
  'images': '../src/assets/images',
  'fonts': '../src/assets/fonts',
  'icons': '../src/assets/icons',
);

$smallScreen: 450px;
$mediumScreen: 992px;
$largeScreen: 1500px;
$xlargeScreen: 1920px;

$black: #000000;
$white: #FFFFFF;
$darkGrey: #231F20;
$mediumGrey: #58595B;
$greyBackground: #f0f0f0;
$lightBackground: #e4e5e6;
$lightGrey: #c6c7c9;
$subtitle: #918F8F;