$small: (
  'columns': 4,
  'gutter': 10,
  'height': 841,
  'outer': 36,
  'width': 390,
);

$medium: (
  'columns': 12,
  'gutter': 16,
  'height': 768,
  'outer': 54,
  'width': 1024,
);

$large: (
  'columns': 12,
  'gutter': 16,
  'height': 800,
  'outer': 54,
  'width': 1280,
);

@function small-grid($c: 1, $f: 0) {
  @return grid($c, map-get($small, 'columns'), map-get($small, 'gutter'), map-get($small, 'outer'), $f);
}

@function medium-grid($c: 1, $f: 0) {
  @return grid($c, map-get($medium, 'columns'), map-get($medium, 'gutter'), map-get($medium, 'outer'), $f);
}

@function large-grid($c: 1, $f: 0) {
  @return grid($c, map-get($large, 'columns'), map-get($large, 'gutter'), map-get($large, 'outer'), $f);
}

#grid {
  .column-line {
    background-color: cyan;
    display: block;
    height: 100vh;
    opacity: 0.25;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: small-grid(1);
    z-index: 9999999999;

    @media (min-width: #{map-get($medium, 'width')}px) {
      width: medium-grid(1);
    }

    @media (min-width: #{map-get($large, 'width')}px) {
      width: large-grid(1);
    }

    &.column-grid-1 {
      left: #{map-get($small, 'outer')}px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: #{map-get($medium, 'outer')}px;
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: #{map-get($large, 'outer')}px;
      }
    }

    &.column-grid-2 {
      left: small-grid(1, map-get($small, 'outer') + map-get($small, 'gutter'));

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: medium-grid(1, map-get($medium, 'outer') + map-get($medium, 'gutter'));
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: large-grid(1, map-get($large, 'outer') + map-get($large, 'gutter'));
      }
    }

    &.column-grid-3 {
      left: small-grid(2, map-get($small, 'outer') + map-get($small, 'gutter'));

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: medium-grid(2, map-get($medium, 'outer') + map-get($medium, 'gutter'));
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: large-grid(2, map-get($large, 'outer') + map-get($large, 'gutter'));
      }
    }

    &.column-grid-4 {
      left: small-grid(3, map-get($small, 'outer') + map-get($small, 'gutter'));

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: medium-grid(3, map-get($medium, 'outer') + map-get($medium, 'gutter'));
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: large-grid(3, map-get($large, 'outer') + map-get($large, 'gutter'));
      }
    }

    &.column-grid-5,
    &.column-grid-6,
    &.column-grid-7,
    &.column-grid-8,
    &.column-grid-9,
    &.column-grid-10,
    &.column-grid-11,
    &.column-grid-12 {
      display: none;

      @media (min-width: #{map-get($medium, 'width')}px) {
        display: block;
      }
    }

    &.column-grid-5 {
      @media (min-width: #{map-get($medium, 'width')}px) {
        left: medium-grid(4, map-get($medium, 'outer') + map-get($medium, 'gutter'));
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: large-grid(4, map-get($large, 'outer') + map-get($large, 'gutter'));
      }
    }

    &.column-grid-6 {
      @media (min-width: #{map-get($medium, 'width')}px) {
        left: medium-grid(5, map-get($medium, 'outer') + map-get($medium, 'gutter'));
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: large-grid(5, map-get($large, 'outer') + map-get($large, 'gutter'));
      }
    }

    &.column-grid-7 {
      @media (min-width: #{map-get($medium, 'width')}px) {
        left: medium-grid(6, map-get($medium, 'outer') + map-get($medium, 'gutter'));
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: large-grid(6, map-get($large, 'outer') + map-get($large, 'gutter'));
      }
    }

    &.column-grid-8 {
      @media (min-width: #{map-get($medium, 'width')}px) {
        left: medium-grid(7, map-get($medium, 'outer') + map-get($medium, 'gutter'));
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: large-grid(7, map-get($large, 'outer') + map-get($large, 'gutter'));
      }
    }

    &.column-grid-9 {
      @media (min-width: #{map-get($medium, 'width')}px) {
        left: medium-grid(8, map-get($medium, 'outer') + map-get($medium, 'gutter'));
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: large-grid(8, map-get($large, 'outer') + map-get($large, 'gutter'));
      }
    }

    &.column-grid-10 {
      @media (min-width: #{map-get($medium, 'width')}px) {
        left: medium-grid(9, map-get($medium, 'outer') + map-get($medium, 'gutter'));
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: large-grid(9, map-get($large, 'outer') + map-get($large, 'gutter'));
      }
    }

    &.column-grid-11 {
      @media (min-width: #{map-get($medium, 'width')}px) {
        left: medium-grid(10, map-get($medium, 'outer') + map-get($medium, 'gutter'));
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: large-grid(10, map-get($large, 'outer') + map-get($large, 'gutter'));
      }
    }

    &.column-grid-12 {
      @media (min-width: #{map-get($medium, 'width')}px) {
        left: medium-grid(11, map-get($medium, 'outer') + map-get($medium, 'gutter'));
      }

      @media (min-width: #{map-get($medium, 'width')}px) {
        left: large-grid(11, map-get($large, 'outer') + map-get($large, 'gutter'));
      }
    }
  }
}
