.imageWrapper {
  position: relative;

  &-img {
    height: 402px;
    object-fit: cover;
    width: 100vw;
    
    &.longImg {
      height: 564px;

      @media (max-width: $smallScreen) {
        height: 294px;
      }
    }
  }

  &-text {
    align-items: center;
    color: white;
    display: flex;
    @include graphik-medium;
    font-size: 60px;
    height: 100%;
    justify-content: center;
    left: 0;
    letter-spacing: 9px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 100%;

    @media (max-width: $smallScreen) {
      font-size: 38px;
      letter-spacing: 5.7px;
    }

    span {
      max-width: 60%;

      @media (max-width: $smallScreen) {
        max-width: 100%;
      }
    }
  }
}