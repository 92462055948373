#navigation {
  background-color: $black;
  color: $white;
  height: 100vh;
  opacity: 0;
  padding: 22px 16px;
  position: fixed;
  width: 100%;
  z-index: -9;

  .inactive {
    opacity: 0.7;
    transition: opacity 0.3s;
  }

  &.nav-light {
    color: $black;

    &.show {
      color: $white;
      @media (min-width: #{map-get($medium, 'width')}px) {            
        color: $black;
      }
      .navigation-content {
        background-color: transparent;
      }
    }

    .nav-list {
      &.collapsible-list {
        .nav-item-head {
          .show-collapsible-list {
            .icon {
              svg {
                fill: $black;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: #{map-get($medium, 'width')}px) {
    background-color: transparent;
    height: initial;
    opacity: 1;
    padding: 25px;
    position: absolute;
    z-index: 9;
  }

  &.show {
    opacity: 1;
    transition: opacity 300ms;
    z-index: 9;

    @media (min-width: #{map-get($medium, 'width')}px) {
      transition: none;
    }

    .navigation-content {
      background-color: $black;
      transition: background-color 300ms;

      .border-bottom {
        &:before {
          @media (min-width: #{map-get($medium, 'width')}px) {
            height: 2px;
          }
        }
      }
    }

    .nav-list {
      &.collapsible-list {
        .nav-item-head {
          .show-collapsible-list {
            .icon {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }

        .nav-item-body {
          @media (min-width: #{map-get($medium, 'width')}px) {
            max-height: 99em;
            opacity: 1;
            overflow: hidden;
          }
        }
      }
    }
  }

  .navigation-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 70px 72px 0;
    position: relative;
    width: 100%;

    @media (min-width: #{map-get($medium, 'width')}px) {
      flex-direction: row;
      flex-wrap: nowrap;
      height: calc(100% - 2px - 2px);
      justify-content: flex-start;
      margin-left: 2px;
      margin-top: 2px;
      padding: 25px 29px 36px;
      transition: background-color 100ms 200ms;
      width: calc(100% - 2px - 2px);
    }

    .border-top,
    .border-side,
    .border-bottom {
      &:before,
      &:after {
        background-color: $white;
        content: '';
        display: block;
        position: absolute;
      }
    }

    .border-top {
      @media (min-width: #{map-get($medium, 'width')}px) {
        display: none;
      }

      &:before {
        left: 25px;
        // width: calc(50% - 15px - 10px - 14px - (168px / 2));
        width: calc(100% - 15px - 10px);

        @media (min-width: #{map-get($medium, 'width')}px) {
          left: 0;
        }
      }

      &:after {
        right: 0;
        width: calc(50% - 14px - (168px / 2));
        display: none;
      }

      &:before,
      &:after {
        height: 2px;
        top: 0;

        @media (min-width: #{map-get($medium, 'width')}px) {
          width: calc(50% - 44px - (168px / 2));
        }
      }
    }

    .border-side {
      @media (min-width: #{map-get($medium, 'width')}px) {
        display: none;
      }

      &:before,
      &:after {
        width: 2px;
      }

      &:before {
        height: calc(100% - 25px);
        left: 0;
        top: 25px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          height: 100%;
          top: 0;
        }
      }

      &:after {
        height: 100%;
        right: 0;
        top: 0;
      }
    }

    .border-bottom {
      &:before {
        bottom: 0;
        height: 2px;
        left: 0;
        width: 100%;

        @media (min-width: #{map-get($medium, 'width')}px) {
          height: 0;
          transition: height 300ms;
        }
      }
    }

    .break-item {
      margin-right: auto !important;
    }
  }

  .nav-list {
    &:not(:last-child) {
      @media (min-width: #{map-get($medium, 'width')}px) {
        margin-right: 52px;
      }
    }

    &.collapsible-list {
      .nav-item-head {
        @media (min-width: #{map-get($medium, 'width')}px) {
          margin-bottom: 16px;
        }

        .show-collapsible-list {
          display: none;

          @media (min-width: #{map-get($medium, 'width')}px) {
            @include financier-regular;
            color: inherit;
            display: block;
            font-size: 12px;
            letter-spacing: 1.8px;
            line-height: 13px;
            padding-right: 32px;
            position: relative;
            text-transform: uppercase;
          }

          .icon {
            @media (min-width: #{map-get($medium, 'width')}px) {
              display: block;
              height: 5px;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%) rotate(0);
              transition: transform 300ms;
              width: 9px;
            }

            svg {
              display: block;
              fill: $white;
              height: auto;
              width: 100%;
            }
          }
        }

        .nav-item {
          @media (min-width: #{map-get($medium, 'width')}px) {
            display: none;
          }
        }
      }

      .nav-item-body {
        @media (min-width: #{map-get($medium, 'width')}px) {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          transition: max-height 300ms, opacity 200ms;
        }
      }
    }
  }

  .nav-item {
    @include graphik-medium;
    font-size: 20px;
    letter-spacing: 3px;
    line-height: 20px;
    margin-bottom: 32px;
    position: relative;
    text-transform: uppercase;
    width: fit-content;

    @media (min-width: #{map-get($medium, 'width')}px) {
      @include financier-regular;
      font-size: 12px;
      letter-spacing: 1.8px;
      line-height: 13px;
      margin-bottom: 16px;
    }

    &.active {
      &:after {
        background-color: $white;
        bottom: -8px;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;

        @media (min-width: #{map-get($medium, 'width')}px) {
          display: none;
        }
      }
    }
  }

  .nav-item,
  .nav-item-head {
    &.hide-small {
      display: none;

      @media (min-width: #{map-get($medium, 'width')}px) {
        display: block;
      }
    }

    &.hide-large {
      @media (min-width: #{map-get($medium, 'width')}px) {
        display: none;
      }
    }
  }
}
