.investments {
  &-how {
    margin-top: 95px;
    margin-bottom: 146px;
  }

  
  &-portfolio {
    .imageWrapper {
      margin-bottom: 124px;
    }

    &__search {
      display: flex;
      justify-content: space-between;
      
      @media (max-width: $smallScreen) {
        flex-direction: column;
      }

      .investments-search-form {
        border-bottom: solid 1px $black;
        margin: 0 auto;
        padding-bottom: 60px;
        position: relative;
        width: small-grid(4);
        display: none;
  
        @media (min-width: #{map-get($medium, 'width')}px) {
          @include same-min-max-width(medium-grid(3));
          border-bottom: none;
          margin: 0;
          padding-bottom: 0;
        }
    
        .investments-search-form-input {
          @include graphik-regular;
          border-bottom: 1px solid $lightGrey;
          display: block;
          font-size: 12px;
          letter-spacing: 0.18px;
          line-height: 12px;
          padding-bottom: 10px;
          padding-right: 22px;
          width: 100%;
        }
    
        .investments-search-form-submit {
          display: block;
          height: 12px;
          position: absolute;
          right: 0;
          top: 0;
          width: 12px;
        }
      }

      .submit-search {
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 16px;
        right: 0;
      }
    }

    &__filters {
      display: flex;
      justify-content: space-between;
      margin: 0 responsive(36, 54);
      
      button {
        @include graphik-medium;
        font-size: 14px;
        letter-spacing: 2.1px;
        text-transform: uppercase;
        margin-top: 45px;
        transition: opacity 300ms;

        @media (max-width: $smallScreen) {
          margin-top: 30px;
        }

        &.active {
          opacity: 0.5;
        }
      }

      @media (max-width: $smallScreen) {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    &__images {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 48px responsive(36, 54);

      @media (max-width: $smallScreen) {
        flex-direction: column;
      }

      &-wrapper {
        position: relative;
        width: 45vw;
        margin: 12px 0;
        transition: opacity 300ms;
        opacity: 1;
        cursor: pointer;

        @media (max-width: $smallScreen) {
          width: 100%;
        }

        &.hide {
          display: none;
        }

        &.unfiltered {
          display: none;
        }

        &.disabled,
        &.no-hover {
          opacity: 0.5;
        }

        .item-title, .item-subtitle {
          @include graphik-medium;
          color: $black;
          font-weight: 500;
          margin-top: 16px;
          text-transform: uppercase;
        }
        
        .item-title {
          font-size: 26px;
          letter-spacing: 3.9px;
        }

        .item-subtitle {
          font-size: 18px;
          letter-spacing: 2.3px;
          margin-bottom: 32px;
        }

        img {
          //height: 45vw;
          height: calc(45vw / 2);
          object-fit: cover;
          pointer-events: none;
          width: 100%;

          @media (max-width: $smallScreen) {
            height: 174px;
          }
        }
      }
    }

    &__content {
      &-wrapper {
        max-height: 0;
        opacity: 0;
        order: 99999;
        overflow: hidden;
        padding: 0;
        position: relative;
        transition: max-height 300ms, padding 300ms, opacity 300ms;
        width: 100%;

        &.show {
          padding: 36px 0 100px;
          max-height: 999em;
          opacity: 1;

          .single-content-close {
            top: 36px;
          }
        }

        &.hide {
          display: none;
        }

        .single-content-name {
          @include graphik-medium;
          font-size: 29px;
          line-height: 29px;
          letter-spacing: 4.35px;
          margin-bottom: 39px;
        }

        .single-content-close {
          width: 10px;
          height: 10px;
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          background-image: url('#{map-get($paths, "icons")}/open.svg');
          background-size: 10px 10px;
          background-position: center;
          transition: top 300ms;
          transform: rotate(45deg);
        }

        .single-content-image {
          width: 100%;
          height: 525px;
          background-position: center;
          background-size: cover;
          margin-bottom: 58px;
        }

        .single-content-title {
          @include graphik-medium;
          font-size: 17px;
          line-height: 17px;
          letter-spacing: 2.55px;
          margin-bottom: 22px;
        }

        .single-content-columns {
          @media (min-width: #{map-get($medium, 'width')}px) {
            display: flex;
            flex-wrap: nowrap;
          }

          .single-content-column-left,
          .single-content-column-right {
            @include financier-regular;
            font-size: 20px;
            line-height: 30px;

            p {
              &:not(:last-child) {
                margin-bottom: 30px;
              }
            }
          }

          .single-content-column-left {
            margin-bottom: 30px;

            @media (min-width: #{map-get($medium, 'width')}px) {
              margin-bottom: 0;
              padding-right: 8px;
              @include same-min-max-width(50%);
            }
          }

          .single-content-column-right {
            @media (min-width: #{map-get($medium, 'width')}px) {
              padding-left: 8px;
              @include same-min-max-width(35%);
            }
          }

          .single-content-column-links {
            margin-bottom: 30px;

            @media (min-width: #{map-get($medium, 'width')}px) {
              @include same-min-max-width(15%);
              padding-left: 16px;
            }

            > p {
              @media (min-width: #{map-get($medium, 'width')}px) {
                text-align: right;
              }

              a {
                @include graphik-medium;
                font-size: 14px;
                letter-spacing: 2.1px;
                line-height: 32px;
              }
            }
          }
        }
      }
    }
  }
}