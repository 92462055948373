.news {
  h1 {
    visibility: hidden;
  }

  &-img {
    img {
      height: 400px;
      object-fit: cover;
      width: 100vw;
    }
  }
}