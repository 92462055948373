#job-listing {
  &:not(:last-child) {
    margin-bottom: 175px;

    @media (min-width: #{map-get($medium, 'width')}px) {
      margin-bottom: 156px;
    }
  }

  .job-listing-main-title {
    @include financier-display-semibold;
    border-bottom: 1px solid $darkGrey;
    color: $darkGrey;
    font-size: 38px;
    letter-spacing: 0.38px;
    line-height: 41px;
    margin: 0 auto;
    padding-bottom: 20px;
    text-transform: uppercase;
    width: small-grid(4);

    @media (min-width: #{map-get($medium, 'width')}px) {
      border-bottom: none;
      padding-bottom: 0;
      width: medium-grid(12);
    }
  }

  .job-listing-table-head {
    display: none;

    @media (min-width: #{map-get($medium, 'width')}px) {
      border-bottom: 1px solid $black;
      display: flex;
      margin: 46px auto 0;
      padding-bottom: 19px;
      width: medium-grid(12);
    }

    .table-head-label {
      @media (min-width: #{map-get($medium, 'width')}px) {
        @include graphik-medium;
        color: rgba($darkGrey, 0.5);
        font-size: 12px;
        letter-spacing: 1.8px;
        line-height: 12px;
        text-transform: uppercase;
      }

      &:not(:last-child) {
        @media (min-width: #{map-get($medium, 'width')}px) {
          margin-right: #{map-get($medium, 'gutter')}px;
        }
      }

      &.table-head-label-title {
        @media (min-width: #{map-get($medium, 'width')}px) {
          @include same-min-max-width(medium-grid(5));
        }
      }

      &.table-head-label-organization,
      &.table-head-label-location,
      &.table-head-label-job-id {
        @media (min-width: #{map-get($medium, 'width')}px) {
          @include same-min-max-width(medium-grid(2));
        }
      }
    }
  }

  .job-listing-table {
    padding: 0 #{map-get($small, 'outer')}px;

    @media (min-width: #{map-get($medium, 'width')}px) {
      padding: 0 #{map-get($medium, 'outer')}px;
    }

    .small-label {
      @include graphik-medium;
      color: rgba($darkGrey, 0.5);
      font-size: 14px;
      letter-spacing: 2.1px;
      line-height: 14px;
      margin-bottom: 20px;
      text-transform: uppercase;

      @media (min-width: #{map-get($medium, 'width')}px) {
        display: none;
      }
    }

    .job-listing-item {
      border-bottom: solid 1px $black;
      padding: 40px 0;

      @media (min-width: #{map-get($medium, 'width')}px) {
        align-items: center;
        display: flex;
      }

      &:last-child {
        margin-bottom: 40px;
      }

      .job-listing-item-title {
        @media (min-width: #{map-get($medium, 'width')}px) {
          @include same-min-max-width(medium-grid(5));
          margin-right: #{map-get($medium, 'gutter')}px;
        }

        &:not(:last-child) {
          margin-bottom: 40px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            margin-bottom: 0;
          }
        }

        .job-listing-value {
          @include graphik-medium;
          color: $black;
          font-size: 20px;
          letter-spacing: 2.5px;
          line-height: 30px;
          text-transform: uppercase;

          @media (min-width: #{map-get($medium, 'width')}px) {
            font-size: 22px;
            letter-spacing: 7.5px;
            line-height: 22px;
          }
        }
      }

      .job-listing-item-cell {
        @media (min-width: #{map-get($medium, 'width')}px) {
          @include same-min-max-width(medium-grid(2));
        }

        &:not(:last-child) {
          margin-bottom: 40px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            @include same-min-max-width(medium-grid(2));
            margin-bottom: 0;
            margin-right: #{map-get($medium, 'gutter')}px;
          }
        }

        &.job-listing-item-job-id {
          @media (min-width: #{map-get($medium, 'width')}px) {
            @include same-min-max-width(medium-grid(1));
          }
        }

        &.job-listing-item-link {
          @media (min-width: #{map-get($medium, 'width')}px) {
            text-align: right;
          }
        }

        .job-listing-value {
          @include financier-regular;
          color: $black;
          font-size: 20px;
          line-height: 22px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            font-size: 16px;
            line-height: 18px;
          }
        }

        .job-listing-link-element {
          @include graphik-medium;
          border-bottom: solid 1px $darkGrey;
          color: $darkGrey;
          font-size: 14px;
          letter-spacing: 2.1px;
          line-height: 14px;
          padding-bottom: 6px;
          text-transform: uppercase;
          width: fit-content;

          @media (min-width: #{map-get($medium, 'width')}px) {
            display: inline-block;
            font-size: 12px;
            letter-spacing: 1.8px;
            line-height: 12px;
            padding-bottom: 7px;
          }

          &:hover {
            border-bottom: solid 1px $lightGrey;
            color: $lightGrey;
            transition: color 300ms, border-bottom 300ms;
          }
        }
      }
    }
  }

  .job-listing-main-link {
    padding: 0 #{map-get($small, 'outer')}px 6px;

    @media (min-width: #{map-get($medium, 'width')}px) {
      padding: 0 #{map-get($medium, 'outer')}px 6px;
    }

    .job-listing-main-link-element {
      @include graphik-medium;
      border-bottom: solid 1px $darkGrey;
      color: $darkGrey;
      font-size: 14px;
      letter-spacing: 2.1px;
      line-height: 14px;
      padding-bottom: inherit;
      text-transform: uppercase;
      width: fit-content;

      &:hover {
        border-bottom: solid 1px $lightGrey;
        color: $lightGrey;
        transition: color 300ms, border-bottom 300ms;
      }
    }
  }
}
