.logosCarousel {
  position: relative;
  width: 100vw;
  
  &__wrapper {
    background-color: $darkGrey;
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding: 48px responsive(36, 54);

    .slick-slide > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
    }

    &-item {
      margin: auto;
      min-height: 76px;
      object-fit: contain;
      width: fit-content;
      max-width: 60%;
    }
  }

  .prev-logo, .next-logo {
    align-items: center;
    background-color: $darkGrey;
    display: flex;
    height: 98%;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 90px;

    @media (max-width: #{map-get($small, 'width')}px) {
      width: 85px;
    }
  }
  
  .prev-logo {
    left: 0;
  }

  .next-logo {
    right: 20px;

    @media (max-width: #{map-get($small, 'width')}px) {
      right: 0;
    }
  }
}
