@use '../abstracts/mixins.scss' as *;

html {
  overflow-x: hidden;

  body {
    @include reset-margin-padding;
    font-family: 'Graphik', serif;
    overflow-x: hidden;
    position: relative;
    min-height: 100%;
    width: 100vw;

    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include font-inherit;
      @include reset-margin-padding;
    }

    p {
      @include reset-margin-padding;
    }

    blockquote {
      @include reset-margin-padding;
    }

    ul {
      @include reset-margin-padding;
      list-style: none;

      li {
        @include reset-margin-padding;
        list-style: none;
      }
    }

    a {
      @include font-inherit;
      text-decoration: none;
    }

    button {    
      @include reset-margin-padding;
      appearance: button;
      background: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      overflow: visible;
      -webkit-tap-highlight-color: transparent;
      text-transform: none;
      -webkit-touch-callout: none;
      user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-user-select: none;
    }

    input {
      background: transparent;
      border: none;
      outline: none;
      @include reset-margin-padding;
      @include font-inherit;
    }

    ::placeholder {
      color: inherit;
      opacity: 0.5;
    }
    
    :-ms-input-placeholder {
      color: inherit;
      opacity: 0.5;
    }
    
    ::-ms-input-placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }
}