.hidden {
  display: none !important;
}

.sectionTitle {
  border-bottom: 1px solid $darkGrey;
  color: $darkGrey;
  @include financier-display-semibold;
  font-size: 34px;
  letter-spacing: 0.64px;
  padding-bottom: 24px;
  margin: 0 responsive(36, 54);
  text-transform: uppercase;

  &.noBorder {
    border-bottom: none;
    padding-bottom: 46px;

    @media (max-width: $smallScreen) {
      border-bottom: 1px solid $darkGrey;
      margin-bottom: 40px;
      padding-bottom: 24px;
    }
  }
}

.descriptionWrapper {
  color: $darkGrey;
  @include financier-display-light;;
  font-size: 26px;
  margin: 96px auto;
  text-align: center;
  max-width: 809px;

  &.studio {
    max-width: 32%;
  }

  @media (max-width: $smallScreen) {
    max-width: 90%;

    &.studio {
      max-width: 90%;
    }
  }

  p {
    margin-bottom: 16px;
  }
}

.link,
.largeLink {
  cursor: pointer;
  @include graphik-medium;
  position: relative;
  text-transform: uppercase;
  
  &::after {
    content: '';
    border-bottom: 1px solid black;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &:hover {
    color: $lightGrey;
    transition: color 0.5s;

    &::after {
      border-bottom: 1px solid $lightGrey;
      transition: border-bottom 0.5s;
    }
  }
}

.link {
  font-size: 12px;
  letter-spacing: 1.8px;
  &::after {
    top: 18px;
  }
}

.largeLink {
  font-size: 14px;
  letter-spacing: 2.1px;
  &::after {
    top: 25px;
  }
}