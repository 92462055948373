#home {
  .hero,
  .hero-carousel {
    &:not(:last-child) {
      margin-bottom: 120px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        margin-bottom: 100px;
      }
    }
  }

  .description-wrapper {
    @include financier-display-light;
    color: $darkGrey;
    font-size: 26px;
    line-height: 34px;
    margin: 0 auto 120px;
    padding: 0 19px;
    text-align: center;

    @media (min-width: #{map-get($medium, 'width')}px) {
      line-height: 34px;
      margin: 0 auto 96px;
      padding: 0;
      width: medium-grid(8, map-get($medium, 'gutter') * 2);
    }

    h3 {
      @include graphik-medium;
      font-size: 26px;
      letter-spacing: 3.5px;
      line-height: 36px;  
      margin-bottom: 36px;
      text-transform: uppercase;
    }

    p {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 34px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          margin-bottom: 35px;
        }
      }
    }
  }

  .image-carousel {
    height: 402px;
    margin-bottom: 162px;
    overflow: hidden;
    width: 100%;

    @media (min-width: #{map-get($medium, 'width')}px) {
      margin-bottom: 110px;
    }

    .image-carousel-content {
      height: inherit;
      position: relative;
      width: 100%;

      .image-carousel-item {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: inherit;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 300ms;
        width: 100%;

        &.active {
          opacity: 1;
        }

        .carousel-item-title {
          @include financier-regular;
          bottom: 19px;
          color: $black;
          font-size: 20px;
          line-height: 19px;
          position: absolute;
          right: 19px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            bottom: 21px;
            line-height: 22px;
            right: #{map-get($medium, 'outer')}px;
          }
        }
      }

      .image-carousel-nav-last,
      .image-carousel-nav-next {
        display: block;
        height: 14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;

        &:hover {
          svg {
            path, line {
              stroke: $white;
              transition: stroke 0.5s;
            }
          }
        }
      }

      .image-carousel-nav-last {
        left: 20px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          left: #{map-get($medium, 'outer') - 16}px;
        }
      }

      .image-carousel-nav-next {
        right: 20px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          right: #{map-get($medium, 'outer') - 16}px;
        }
      }
    }
  }

  .our-platform-accordion {
    margin-bottom: 108px;
    padding: 0 #{map-get($small, 'outer')}px;

    @media (min-width: #{map-get($medium, 'width')}px) {
      margin-bottom: 100px;
      padding: 0 #{map-get($medium, 'outer')}px;
    }

    .our-platform-title {
      @include financier-display-semibold;
      border-bottom: 1px solid $darkGrey;
      color: $darkGrey;
      font-size: 34px;
      letter-spacing: 0.34px;
      line-height: 37px;
      margin: 0 auto;
      padding-bottom: 20px;
      text-transform: uppercase;
      width: small-grid(4);

      @media (min-width: #{map-get($medium, 'width')}px) {
        width: 100%;
      }
    }

    .accordion-item {
      border-bottom: solid 1px $black;
      padding: 48px 0;

      @media (min-width: #{map-get($medium, 'width')}px) {
        padding: 40px 0;
      }

      &.show {
        .accordion-item-show {
          .icon {
            transform: translateY(-50%) rotate(225deg);
          }
        }

        .accordion-item-content {
          margin-top: 32px;
          max-height: 999em;
          opacity: 1;

          @media (min-width: #{map-get($medium, 'width')}px) {
            margin-top: 20px;
          }
        }
      }

      .accordion-item-show {
        @include graphik-medium;
        display: block;
        font-size: 26px;
        letter-spacing: 3.25px;
        line-height: 32px;
        padding-right: 20px;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        width: 100%;

        &:hover {
          color: $lightGrey;
          transition: color 0.5s;

          .icon {
            transform: translateY(-50%) rotate(225deg);
          }
        }

        @media (min-width: #{map-get($medium, 'width')}px) {
          width: 100%;
        }

        .icon {
          background-image: url('#{map-get($paths, "icons")}/open.svg');
          background-size: contain;
          background-position: center;
          display: block;
          height: 10px;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotate(0);
          transition: transform 300ms;
          width: 10px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            height: 15px;
            width: 15px;
          }
        }
      }

      .accordion-item-content {
        @include financier-regular;
        color: $black;
        font-size: 20px;
        line-height: 30px;
        margin-top: 0;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height 300ms, margin-top 300ms, opacity 300ms;
        width: 100%;

        @media (min-width: #{map-get($medium, 'width')}px) {
          width: medium-grid(10);
        }

        p {
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 48px;

            @media (min-width: #{map-get($medium, 'width')}px) {
              margin-bottom: 20px;
            }
          }
        }

        .item-content-link {
          width: 100%;
          margin-bottom: 3px;

          .item-content-link-element {
            @include graphik-medium;
            border-bottom: solid 1px $darkGrey;
            color: $darkGrey;
            display: inline-block;
            font-size: 14px;
            letter-spacing: 2.1px;
            line-height: 14px;
            max-width: 100%;
            padding-bottom: 6px;
            text-transform: uppercase;
            width: fit-content;

            &:hover {
              border-bottom: solid 1px $lightGrey;
              color: $lightGrey;
              transition: color 0.5s, border-bottom 0.5s;
            }
          }
        }
      }
    }
  }

  .careers-content {
    &:not(:last-child) {
      margin-bottom: 151px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        margin-bottom: 165px;
      }
    }

    .careers-content-image {
      align-items: center;
      background-position: center;
      background-size: cover;
      display: flex;
      height: 400px;
      justify-content: center;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 100px;
      }

      .careers-content-image-title {
        @include graphik-medium;
        color: $white;
        font-size: 38px;
        letter-spacing: 9px;
        line-height: 80px;
        text-align: center;
        text-transform: uppercase;
        width: small-grid(4);

        @media (min-width: #{map-get($medium, 'width')}px) {
          font-size: 60px;
          line-height: 60px;
          width: medium-grid(10);
        }
      }
    }

    .careers-content-description {
      @include financier-display-light;
      color: $darkGrey;
      font-size: 26px;
      line-height: 34px;
      padding: 0 #{map-get($small, 'outer')}px;
      text-align: center;

      @media (min-width: #{map-get($medium, 'width')}px) {
        line-height: 35px;
        margin: 0 auto;
        padding: 0;
        width: medium-grid(8, map-get($medium, 'gutter') * 2);
      }

      p {
        &:not(:last-child) {
          margin-bottom: 34px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            margin-bottom: 35px;
          }
        }
      }
    }
  }

  .key-strengths {
    &:not(:last-child) {
      margin-bottom: 120px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        margin-bottom: 100px;
      }
    }

    .key-strengths-title {
      @include financier-display-semibold;
      border-bottom: 1px solid $darkGrey;
      color: $darkGrey;
      font-size: 34px;
      letter-spacing: 0.34px;
      line-height: 37px;
      margin: 0 auto;
      padding-bottom: 20px;
      text-transform: uppercase;
      width: small-grid(4);

      @media (min-width: #{map-get($medium, 'width')}px) {
        width: medium-grid(12);
      }

      &:not(:last-child) {
        margin-bottom: 60px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          margin-bottom: 0;
        }
      }
    }

    .key-strengths-list {
      padding: 0 #{map-get($small, 'outer')}px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        display: flex;
        flex-wrap: wrap;
        padding: 0 #{map-get($medium, 'outer')}px;
        position: relative;
      }

      &:after {
        @media (min-width: #{map-get($medium, 'width')}px) {
          background-color: $black;
          bottom: 0;
          content: '';
          display: block;
          height: 1px;
          left: 0;
          width: 100%;
        }
      }

      .key-strengths-item {
        margin-bottom: 100px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          @include same-min-max-width(medium-grid(4));
          margin: 50px #{map-get($medium, 'gutter')}px 50px 0;
        }

        &:nth-child(3n + 3) {
          @media (min-width: #{map-get($medium, 'width')}px) {
            margin: 50px 0;
          }
        }

        &:last-child {
          border-bottom: solid 1px $black;
          margin-bottom: 0;
          padding-bottom: 80px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            border-bottom: none;
            margin-bottom: 50px;
            padding-bottom: 0;
          }
        }

        .key-strengths-item-name {
          @include graphik-medium;
          color: $darkGrey;
          font-size: 14px;
          letter-spacing: 2.1px;
          line-height: 14px;
          text-transform: uppercase;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 18px;
          }
        }

        .key-strengths-item-description {
          @include financier-regular;
          color: $black;
          font-size: 16px;
          line-height: 26px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            width: medium-grid(3);
          }

          p {
            &:not(:last-child) {
              margin-bottom: 26px;
            }
          }
        }
      }
    }
  }

  .our-people {
    &:not(:last-child) {
      margin-bottom: 138px;

      @media (min-width: #{map-get($medium, 'width')}px) {
        margin-bottom: 125px;
      }
    }

    .our-people-image {
      align-items: center;
      background-position: center;
      background-size: cover;
      display: flex;
      height: 294px;
      justify-content: center;
      width: 100%;
      position: relative;

      @media (min-width: #{map-get($medium, 'width')}px) {
        height: 564px;
      }

      &:not(:last-child) {
        margin-bottom: 111px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          margin-bottom: 137px;
        }
      }

      .our-people-overlay {
        // background-color: red;
        // opacity: 0.5;
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left: 0;
      }

      .our-people-image-title {
        @include graphik-medium;
        color: $white;
        font-size: 27px;
        letter-spacing: 3.38px;
        line-height: 40px;
        text-align: center;
        text-transform: uppercase;
        width: small-grid(4);
        z-index: 9;

        @media (min-width: #{map-get($medium, 'width')}px) {
          font-size: 60px;
          letter-spacing: 9px;
          line-height: 80px;
          width: medium-grid(10);
        }
      }
    }

    .our-people-description {
      @include financier-display-light;
      color: $darkGrey;
      font-size: 26px;
      line-height: 34px;
      padding: 0 #{map-get($small, 'outer')}px;
      text-align: center;

      @media (min-width: #{map-get($medium, 'width')}px) {
        line-height: 35px;
        margin: 0 auto;
        padding: 0;
        width: medium-grid(8, map-get($medium, 'gutter') * 2);
      }

      p {
        &:not(:last-child) {
          margin-bottom: 34px;

          @media (min-width: #{map-get($medium, 'width')}px) {
            margin-bottom: 35px;
          }
        }
      }
    }
  }

  .jobs-iframe-container {
    margin: 0 auto 104px;
    width: calc(100% - #{map-get($small, 'outer') * 2}px);

    @media (min-width: #{map-get($medium, 'width')}px) {
      margin: 0 auto 58px;
      width: calc(100% - #{map-get($medium, 'outer') * 2}px);
    }
  }
}
