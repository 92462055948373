.hero-carousel {
  background-color: $black;
  background-position: center;
  background-size: cover;
  display: flex;
  min-height: 100vh;
  padding: 22px 16px;
  position: relative;
  width: 100%;

  @media (min-width: #{map-get($medium, 'width')}px) {
    padding: 25px;
  }

  &.bridgeton-capital {
    .hero-content {
      .border-top {
        &:before,
        &:after {
          @media (min-width: #{map-get($medium, 'width')}px) {
            width: calc(50% - 28px - (248px / 2));
          }
        }
      }
    }
  }

  .hero-content {
    align-items: center;
    display: flex;
    height: inherit;
    justify-content: center;
    position: relative;
    width: 100%;
    flex-direction: column;

    .border-top,
    .border-side,
    .border-bottom {
      &:before,
      &:after {
        background-color: $white;
        content: '';
        display: block;
        position: absolute;
      }
    }

    .border-top {
      &:before {
        left: 25px;
        // width: calc(50% - 15px - 10px - 14px - (168px / 2));
        width: calc(100% - 15px - 10px);

        @media (min-width: #{map-get($medium, 'width')}px) {
          left: 0;
          width: 100%;
        }
      }

      &:after {
        right: 0;
        width: calc(50% - 14px - (168px / 2));
        display: none;
      }

      &:before,
      &:after {
        height: 2px;
        top: 0;

        // @media (min-width: #{map-get($medium, 'width')}px) {
        //   width: calc(50% - 44px - (168px / 2));
        // }
      }
    }

    .border-side {
      &:before,
      &:after {
        width: 2px;
      }

      &:before {
        height: calc(100% - 25px);
        left: 0;
        top: 25px;

        @media (min-width: #{map-get($medium, 'width')}px) {
          height: 100%;
          top: 0;
        }
      }

      &:after {
        height: 100%;
        right: 0;
        top: 0;
      }
    }

    .border-bottom {
      &:before,
      &:after {
        bottom: 0;
        height: 2px;
        width: calc(50% - 22px - (30px / 2));
      }

      &:before {
        left: 0;
        width: calc(50% - 22px - (30px / 2));
      }

      &:after {
        right: 0;
        width: calc(50% - 22px - (30px / 2));
      }
    }
  }

  .hero-carousel-container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;

    .hero-carousel-item {
      width: 100%;
      height: 100vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .hero-carousel-last,
  .hero-carousel-next {
    display: block;
    height: 14px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    z-index: 9;

    &:hover {
      svg {
        path, line {
          stroke: $white;
          transition: stroke 0.5s;
        }
      }
    }
  }

  .hero-carousel-last {
    left: 30px;

    @media (min-width: #{map-get($medium, 'width')}px) {
      left: #{map-get($medium, 'outer') - 6}px;
    }
  }

  .hero-carousel-next {
    right: 30px;

    @media (min-width: #{map-get($medium, 'width')}px) {
      right: #{map-get($medium, 'outer') - 6}px;
    }
  }

  #show-navigation {
    height: 15px;
    left: 16px;
    position: absolute;
    top: 22px;
    width: 15px;
    z-index: 999;
    transition: transform 100ms;

    @media (min-width: #{map-get($medium, 'width')}px) {
      display: none;
    }

    &.show {
      svg {
        polygon {
          &:nth-child(1) {
            transform: translateY(0) rotate(45deg);
            transform-origin: center;
            transition: transform 300ms;
            transform: translateY(0) rotate(45deg);
            -ms-transform: translateY(0) rotate(45deg);
            -webkit-transform: translateY(0) rotate(45deg);
            transform-origin: center;
            -ms-transform-origin: center;
            -webkit-transform-origin: center;
            transition: -webkit-transform 300ms;
            -webkit-transition: -webkit-transform 300ms;
            transition: transform 300ms, -webkit-transform 300ms;
            transition: transform 300ms;
            -o-transition: transform 300ms;
          }

          &:nth-child(2),
          &:nth-child(3) {
            transform: translateY(0) rotate(-45deg);
            -ms-transform: translateY(0) rotate(-45deg);
            -webkit-transform: translateY(0) rotate(-45deg);
            transform-origin: center;
            -ms-transform-origin: center;
            -webkit-transform-origin: center;
            transition: -webkit-transform 300ms;
            -webkit-transition: -webkit-transform 300ms;
            transition: transform 300ms, -webkit-transform 300ms;
            transition: transform 300ms;
            -o-transition: transform 300ms;
          }
        }
      }
    }

    svg {
      display: block;
      width: 100%;
      height: auto;
      fill: $white;

      polygon {
        &:nth-child(1) {
          transform: translateY(33.33%) rotate(0);
          -ms-transform: translateY(33.33%) rotate(0);
          -webkit-transform: translateY(33.33%) rotate(0);
        }

        &:nth-child(2) {
          transform: translateY(0) rotate(0);
          -ms-transform: translateY(0) rotate(0);
          -webkit-transform: translateY(0) rotate(0);
        }

        
        &:nth-child(3) {
          transform: translateY(-33.33%) rotate(0);
          -ms-transform: translateY(-33.33%) rotate(0);
          -webkit-transform: translateY(-33.33%) rotate(0);
        }
      }
    }
  }

  .go-to-next {
    bottom: 15px;
    height: 14px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 30px;

    @media (min-width: #{map-get($medium, 'width')}px) {
      bottom: 18px;
    }
  }
}
